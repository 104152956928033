import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/form-field/FormField.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const LegacyPatternLink = makeShortcode("LegacyPatternLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/form-field" storybook="https://workday.github.io/canvas-kit/?path=/story/components-inputs-form-field--basic" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <p>{`The Form Field is a wrapper component intended to be used with Canvas Kit input components when
designing a form to ensure they meet accessibility standards. Inputs collect data from users within
a form. It’s important to choose the right input to elicit a response in the format you want. For
more information on best practices for designing forms, reference the `}<LegacyPatternLink href="/patterns/forms/" mdxType="LegacyPatternLink">{`Forms Pattern`}</LegacyPatternLink>{` documentation.`}</p>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use the Form Field component as a wrapper for most inputs on a form. From a designer perspective,
it is not a visible component but something to be aware of when working with developers on a form
as the Canvas Kit Form Field should encapsulate input components on a form.`}</li>
        <li parentName="ul">{`Use form fields to ensure forms meet accessibility guidelines.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
            "href": "/components/containers/table"
          }}>{`Table`}</a>{` when presenting and editing sets of
repeating data with the same structure.`}</li>
        <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
            "href": "/components/popups/popup"
          }}>{`Popup`}</a>{` or `}<a parentName="li" {...{
            "href": "/components/popups/toast"
          }}>{`Toast`}</a>{` component
to display confirmation messages or validate user inputs in the context of a user action.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Reference the `}<LegacyPatternLink href="/patterns/forms/" mdxType="LegacyPatternLink">{`Form Pattern Guidance`}</LegacyPatternLink>{` for more information on designing forms and
strategies to enable the best user expeience of form content.`}</li>
        <li parentName="ul">{`For content guidance regarding input types for forms, see `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/checkboxes"
          }}>{`Checkbox`}</a>{`,
`}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/dropdown-menus"
          }}>{`Dropdown`}</a>{`, `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/empty-states"
          }}>{`Empty State`}</a>{`,
`}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/error-alert-messages"
          }}>{`Errors and Alerts`}</a>{`,
`}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/field-labels"
          }}>{`Field Labels`}</a>{`, and `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/radio-buttons"
          }}>{`Radio Buttons`}</a>{`
in the Content Style Guide.`}</li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      